* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy" !important;
}

#widget-container {
  border-color: transparent !important;
}

*:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

#map-container canvas {
  border-radius: 14px;
}

button {
  cursor: pointer !important;
}

#root {
  overflow-x: hidden;
}

.dark {
  background: #121212;
}

.light {
  background: #f7f8f9;
}
.dis-block {
  display: block;
}

.mb-2 {
  margin-bottom: 16px;
}

.whiteBg {
  background: #fff;
}

.link-disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

@media (min-width: 1200px) {
  .MuiContainer-maxWidthLg {
    padding: 0 !important;
  }
}

.link {
  text-decoration: underline;
  color: #fce34d;
  font-weight: 300;
}
.form__eyes {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
}

.MuiTabs-indicator {
  display: none;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1111;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
/* other */
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
label + .MuiInput-formControl {
  padding: 14px !important;
  margin: 0 !important;
}

.black-bg {
  background: #000;
}

.red {
  color: #ef4444;
}

.green {
  color: #10b981;
}

.yellow {
  color: #fce34d;
}
/* icon */
.icon-md {
  width: 20px;
  height: 20px;
  max-width: 20px;
  height: 20px;
  object-fit: contain;
}
.icon-lg {
  max-width: 60px;
  height: 60px;
  object-fit: contain;
}
/* tab */
.react-tabs {
  margin-top: 24px;
}
.react__tab-content {
  padding-bottom: 20px;
  width: 100%;
  background: #212738;
}
.react-tabs__tab-list {
  background: #ffffff;
  box-shadow: 0px 0px 1px #e2e8f0;
  border-radius: 13px;
  padding: 24px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  height: fit-content;
}
.faq__tab-content {
  display: flex;
  flex-wrap: wrap;
}
.react-tabs-panel {
  display: flex;
  column-gap: 16px;
}
.react-tabs__tab--selected {
  background: #fce34d;
  border-radius: 8px;
  font-weight: 500 !important;
  font-size: 23px;
  line-height: 28px;
  padding: 12px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000 !important;
  transition: all 0.3s;
}
.react-tabs__tab {
  color: #b8c0cc;
  font-size: 23px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  font-weight: 200;
  margin: 0 auto 38px;
}
.react-tabs__tab:last-child {
  margin-bottom: 0;
}
.courses {
  padding: 40px 0;
}
/* table */
table th {
  font-size: 20px !important;
  line-height: 24px !important;
  color: #64748b !important;
  padding: 30px 0 20px !important;
  font-weight: 300 !important;
  border-bottom: 2px solid #e7eaee !important;
}
table td {
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
}
@media (max-width: 768px) {
  .icon-lg {
    width: 39px;
    height: 39px;
  }
}

.searchIcon {
  position: relative;
}

.dateSearch {
  position: relative;
  height: 30px;
  overflow: visible;
}
.cryptoIcon {
  position: relative;
  height: 30px;
  overflow: visible;
}

.dateSearch:before {
  content: "";
  position: absolute;
  top: -19%;
  left: -30px;
  width: 24px;
  height: 24px;
  background: url("../../src/assets/images/state/calendar.svg");
  z-index: 2;
}

.cryptoIcon:before {
  content: "";
  position: absolute;
  top: -19%;
  left: -30px;
  width: 24px;
  height: 24px;
  background: url("../../src/assets/images/statistic/bitcoin-refresh.svg");
  z-index: 2;
}
.searchIcon::before {
  content: "";
  position: absolute;
  top: 28%;
  right: 17px;
  width: 24px;
  height: 24px;
  background: url("../../src/assets/images/state/search.svg");
  z-index: 2;
}

/* tab */
.MuiTabs-flexContainer {
  flex-direction: column !important;
  align-items: center;
}
.PrivateTabIndicator-colorSecondary-45 {
  display: none !important;
}
.css-19kzrtu {
  padding: 0 !important;
}
.swiper-wrapper.disabled {
  transform: translate3d(0px, 0, 0) !important;
}
.swiper-pagination.disabled {
  display: none;
}

.game {
  width: 100%;
  height: 200px;
  border: 1px dashed #fce34d;
  margin: 40px auto;
}

.dino {
  width: 50px;
  height: 50px;
  background-image: url("../assets/images/state/dollarCoin.webp");
  background-size: 50px 50px;
  position: relative;
  top: 150px;
}

.jump {
  animation: jump 0.3s linear;
}

@keyframes jump {
  0% {
    top: 150px;
  }

  30% {
    top: 130px;
  }

  50% {
    top: 80px;
  }

  80% {
    top: 130px;
  }

  100% {
    top: 150px;
  }
}

.cactus {
  width: 20px;
  height: 40px;
  position: relative;
  top: 110px;
  left: 50%;

  background-image: url("../assets/images/state/dollarCoin.webp");
  background-size: 20px 40px;

  animation: block 1s infinite linear;
}

@keyframes block {
  0% {
    left: 580px;
  }

  100% {
    left: -20px;
  }
}

.timer__items {
  display: flex;

  font-size: 48px;
  color: #000000;
}
.timer__item {
  position: relative;
  min-width: 60px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 15px;
  text-align: center;
}

.timer__item:first-child {
  margin-left: 0;
}
.timer__item::before {
  content: attr(data-title);
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  font-size: 14px;
}
.timer__item:not(:last-child)::after {
  content: ":";
  position: absolute;
  right: -15px;
}
