@import "./global.css";
@import url("../assets/font/stylesheet.css");
body {
  background: #f7f8f9;
  color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}

html.theme-dark {
  background: #111115 !important;
}

:root {
  --primary: #fce34d;
}

*::selection {
  background: #fce34d;
  color: #000;
}

a {
  color: #fff;
}

a {
  text-decoration: none;
}

.primary {
  color: #fce34d;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.black {
  color: #000;
}

.txt-btn:hover {
  text-decoration: underline;
}

.label {
  margin-top: 16px;
  font-style: normal;
  margin-bottom: 8px;
  display: block;
  font-weight: 500;
  text-align: left;
  font-size: 18px;
}
.label-lg {
  margin-top: 16px;
  font-style: normal;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  text-align: left;
  font-size: 23px;
  font-weight: 500;
}
.dashboardSlider .swiper-container {
  border-radius: 8px !important;
  height: 200px;
  margin-bottom: 40px;
}

.dashboardSlider .swiper-pagination {
  bottom: 10px;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
}

.dashboardSlider .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  margin: 0px 9px;
  display: block;
  border-radius: 100%;
  cursor: pointer;
}

.dashboardSlider .swiper-pagination-bullet-active {
  background: #ffffff;
}

@media (max-width: 768px) {
  .label {
    font-size: 19px;
  }

  .dashboardSlider .swiper-container {
    border-radius: 8px !important;
    height: 111px;
    margin-bottom: 16px;
  }

  .dashboardSlider .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0px 3px;
  }
}

.scrolled-body {
  overflow-y: scroll !important;
  padding: 0px !important;
}
